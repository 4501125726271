<template>
  <AppBar>
    <ListData
      isApproval
      :tableData="tableData"
      isBtn="123"
      :callBack="getData"
      @RefreshData="RefreshData"
    ></ListData>
  </AppBar>
</template>
<style lang="less" scoped></style>
<script>
import AppBar from "../../components/AppBar";
import ListData from "../../components/ListData";
export default {
  data() {
    return {
      tableData: []
    };
  },
  methods: {
    RefreshData() {
      this.getData();
    },
    getData() {
      this.tableData = [];
      if (sessionStorage.getItem("role_id") == "3") {
        this.$common.getInterface(
          "/api/v1.projects/getTasks",
          {state: 0},
          this,
          res => {
            let id = sessionStorage.getItem("id");
            res.data.data.forEach(item => {
              let nowDate =
                new Date().getFullYear() +
                "/" +
                (new Date().getMonth() + 1) +
                "/" +
                new Date().getDate();
              if (
                (item.id_headman == id &&
                  new Date(item.id_headman_date.replace(/-/g, '/') + " 23:59:59").getTime() >
                    new Date(nowDate).getTime()) ||
                (item.id_workers.indexOf(id) != -1 &&
                  new Date(item.id_workers_date.replace(/-/g, '/') + " 23:59:59").getTime() >
                    new Date(nowDate).getTime())
              ) {
                this.tableData.push(item);
              }
            });
          }
        )
        // this.$common.getInterface(
        //   "/api/v1.projects/getCheckedLists",
        //   // "/api/v1.projects/getTasks",
        //   {},
        //   this,
        //   res => {
        //     // console.log(res);
        //     // this.tableData = res.data.data;
        //     let id = sessionStorage.getItem("id");
        //     res.data.data.forEach(item => {
        //       let nowDate =
        //         new Date().getFullYear() +
        //         "/" +
        //         (new Date().getMonth() + 1) +
        //         "/" +
        //         new Date().getDate();
        //       if (
        //         (item.id_headman == id &&
        //           new Date(item.id_headman_date.replace(/-/g, '/')).getTime() >
        //             new Date(nowDate).getTime()) ||
        //         (item.id_workers.indexOf(id) != -1 &&
        //           new Date(item.id_workers_date.replace(/-/g, '/')).getTime() >
        //             new Date(nowDate).getTime())
        //       ) {
        //         this.tableData.push(item);
        //       }
        //     });
        //   }
        // );
      } else {
        this.$common.getInterface(
          "/api/v1.projects/getMyPost",
          {},
          this,
          res => {
            // console.log(res);
            this.tableData = res.data.data;
          }
        );
      }
    }
  },
  created() {
    this.getData();
  },
  components: {
    AppBar,
    ListData
  }
};
</script>
